import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Img from "gatsby-image";
import { BackgroundSection, Section } from "../components/section";
import { Container, Row, Col } from "reactstrap";
import Layout from '../components/layout';
export const query = graphql`
   {
    certificateImg:     file(relativePath: { eq: "certificat.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
  }
`;
export const _frontmatter = {
  "title": "Certificats",
  "order": 5
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};

const MDXLayout = ({
  children,
  ...props
}) => <Layout {...props}>
  <Section>
    <Container>
      <Row>
        <Col md={6}>
          {children}
        </Col>
        <Col md={6}>
          <Img fluid={props.data.certificateImg.childImageSharp.fluid} className="shadow" />
        </Col>
      </Row>
    </Container>
  </Section>
</Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Certificats`}</h1>
    <h2>{`Pa de pagès català`}</h2>
    <p>{`La Reglamentació del Consell Regulador de la Indicació Geogràfica Protegida (IGP) Pa de Pagès Català defineix el nostre producte com un pa tradicional, rodó, de crosta cruixent, molla tendra i alveolat gran, on al menys el format es realitza de forma manual. Tot el procés es realitza seguint una elaboració tradicional, amb fermentacions lentes, coent sempre les masses en forns de solera refractària.`}</p>
    <p>{`Per més informació --  `}<a parentName="p" {...{
        "href": "http://padepagescatala.org/regulations/"
      }}>{`http://padepagescatala.org/regulations/`}</a></p>
    <h2>{`Botiga centenària`}</h2>
    <p>{`La botiga situada al carrer Torregassa 29 de l'Ametlla del Vallès té més de 100 anys d'història entre les seves parets i obrador.`}</p>
    <p>{`El forn de Pa va començar l'any 1947 i fins a dia d'avui seguim oferint lo millor de nosaltres.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      